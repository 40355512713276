import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/burnt-offerings-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1976 gothic horror, Burnt Offerings"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 17</h1>
                    <h2>Burnt Offerings (Bonus)</h2>
                    <h3>September 11, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Dan Curtis // <b>Starring:</b> Oliver Reed &amp; Karen Black</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/17-Burnt-Offerings-wguest-Sam-Pancake-Bonus-e28qa25" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe! horror podcast episode 17, Burnt Offerings"></iframe>
                        </div>
                        <p><Link to="/transcripts/burnt-offerings">Read the episode transcript</Link></p>
                        <p>This week Bryan and Dave are joined by actor, comedian, and made-for-tv movie expert, Sam Pancake to talk about Dan Curtis (of Dark Shadows fame) and his 1976 gothic melodrama, Burnt Offerings. We cover the movie, of course, hear a nice tale of how pleasant and sweet Karen Black was, much to our delight, and hear a second-hand tale about Oliver Reed's tattooed penis.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=Oo_6Fb5k2lo" target="_blank" rel="noreferrer">⁠https://www.youtube.com/watch?v=Oo_6Fb5k2lo</OutboundLink>⁠</p>
                        <p>Sam Pancake on Twitter: <OutboundLink href="https://twitter.com/jsampancake" target="_blank" rel="noreferrer">⁠https://twitter.com/jsampancake</OutboundLink>⁠</p>
                        <p>Sam Pancake on Instagram: <OutboundLink href="https://www.instagram.com/thesampancake/" target="_blank" rel="noreferrer">⁠https://www.instagram.com/thesampancake/</OutboundLink>⁠</p>
                        <p>Sam's podcast, The Monday Afternoon Movie: <OutboundLink href="https://www.patreon.com/mondayafternoonmovie" target="_blank" rel="noreferrer">⁠https://www.patreon.com/mondayafternoonmovie</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-peanut-butter-solution">Next Episode</Link></li>
                        <li><Link to="/episodes/the-baby">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)